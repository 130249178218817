import {
  getAllDynamicPageSlugs,
  getDynamicPage,
  getGlobalData,
} from "@api/handlers";
import { layoutAdapter } from "@layout/Layout.adapter";
import { handleStaticProps } from "@utils/handle-static/handle-static-props";
import { isPreview } from "@utils/is-preview/isPreview";
import { dynamicPageAdapter } from "components/pages/dynamic-page/DynamicPage.adapter";
import { GetStaticPaths, GetStaticProps } from "next";
import { Pages } from "../../components/pages";

export const getStaticProps: GetStaticProps = async (context) => {
  const preview = isPreview(context);
  if (!context.locale) throw new Error("Locale is not defined");
  const capabilityId = context.params?.capability;

  const response = await getDynamicPage({
    preview,
    locale: context.locale,
    type: "capabilities",
    slug: capabilityId,
  });

  const props = dynamicPageAdapter({
    ...response,
    pageName: "Capabilities",
  });
  const globalData = await getGlobalData({ locale: context.locale });
  const globalProps = layoutAdapter(globalData);
  return handleStaticProps({
    props: { ...props, ...globalProps },
    context,
    requiredData: response.page,
  });
};

export const getStaticPaths: GetStaticPaths = async () => {
  const slugs = await getAllDynamicPageSlugs({
    preview: false,
    type: "capabilities",
    slugName: "capability",
  });
  return {
    paths: slugs.map((capability) => ({ params: { capability } })),
    fallback: "blocking",
  };
};

export default Pages.DynamicPage;
